import { FeatureNameEnum, WebAppListeningExperienceOverhaulVariant } from 'constants/featureDefinitions';
import { getFeatureVariant, useFeatureVariant } from 'hooks/useFeatureFlags';

import { CustomVariantMapper } from '@speechifyinc/lib-feature-flag';

const FORCED_ENABLED_VARIANT_MAPPER = {
  variant: WebAppListeningExperienceOverhaulVariant.FORCED_ENABLED,
  condition: async () => {
    if (typeof window !== 'undefined' && window.location.host.startsWith('beta-app.speechify.com')) {
      return true;
    }
    return false;
  }
} satisfies CustomVariantMapper<WebAppListeningExperienceOverhaulVariant.FORCED_ENABLED>;

const isVariantEnabled = (variant: WebAppListeningExperienceOverhaulVariant) => {
  return variant === WebAppListeningExperienceOverhaulVariant.ENABLED || variant === WebAppListeningExperienceOverhaulVariant.FORCED_ENABLED;
};

export async function getIsOverhaulListeningExperienceEnabled(): Promise<boolean> {
  const variant = await getFeatureVariant(FeatureNameEnum.WEB_APP_LISTENING_EXPERIENCE_OVERHAUL, {
    customVariantsMapper: [FORCED_ENABLED_VARIANT_MAPPER]
  });

  return isVariantEnabled(variant);
}

type HookResult =
  | {
      isLoading: true;
    }
  | {
      isLoading: false;
      isOverhaulListeningExperienceEnabled: boolean;
    };

export function useIsOverhaulListeningExperienceEnabled(): HookResult {
  const variant = useFeatureVariant(FeatureNameEnum.WEB_APP_LISTENING_EXPERIENCE_OVERHAUL, {
    customVariantsMapper: [FORCED_ENABLED_VARIANT_MAPPER]
  });

  if (variant.isLoading) {
    return { isLoading: true };
  }
  return {
    isLoading: false,
    isOverhaulListeningExperienceEnabled: isVariantEnabled(variant.variant)
  };
}
