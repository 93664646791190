import React, { createElement, useMemo } from 'react';

import { TranslationKeys, useTranslation } from 'hooks/useTypedTranslation';
import ReactTooltip from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { v4 } from 'uuid';

interface ImportSourceCardProps {
  icon: (props: { className?: string }) => React.ReactNode;
  buttonText: TranslationKeys['common'] | JSX.Element;
  buttonType: 'solid' | 'outline';
  title: TranslationKeys['common'];
  text?: TranslationKeys['common'];
  onClick?: (e: React.MouseEvent) => void;
}

export function ImportSourceCard({ icon, buttonText, buttonType, title, text, onClick }: ImportSourceCardProps) {
  const { t } = useTranslation('common');
  const id = useMemo(() => v4(), []);

  return (
    <div className="grid grid-cols-2 gap-2 justify-between items-center w-full px-4 py-3 cursor-pointer -m-px" onClick={onClick}>
      <div>{createElement(icon, { className: 'w-10 h-10' })}</div>
      <div className="text-right">
        <button
          className={twMerge(
            'text-sm font-medium px-3 rounded-[20px] transition-colors min-w-20 text-center',
            buttonType === 'outline' &&
              'py-[2px] text-electric-400 border border-electric-400 hover:border-electric-hovered-400 active:border-electric-pressed-400',
            buttonType === 'solid' && 'border-none py-[3px] text-glass-0 border bg-electric-400 hover:bg-electric-hovered-400 active:bg-electric-pressed-400'
          )}
        >
          {typeof buttonText === 'string' ? t(buttonText) : buttonText}
        </button>
      </div>
      <div className="shrink-0 font-bold text-lg leading-6 text-glass-700 whitespace-nowrap">{t(title)}</div>
      <div className="text-sm text-glass-500 whitespace-nowrap overflow-hidden text-ellipsis" data-tip data-for={id}>
        {text ? t(text) : undefined}
      </div>
      {text && (
        <ReactTooltip id={id} place="top" type="dark" effect="solid" className="!p-2 !leading-4 !border-x-0 !border-t-0">
          <span className="text-xs text-glass-1 font-ABCDiatype">{t(text)}</span>
        </ReactTooltip>
      )}
    </div>
  );
}
