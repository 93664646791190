import { RecordType } from 'interfaces';
import { ALLOWED_MIME_TYPES, TextImport, UrlImport } from 'interfaces/import';
import { IntegrationFile, IntegrationFileType, IntegrationFolder, IntegrationItemRecord, SORT_BY_FIELD, SORT_ORDER, SortValues } from 'interfaces/integrations';

import { NEW_INSTANT_LISTENING_SUPPORTED_MIME_TYPES, ROOT_FOLDER_ID } from './constants';

export function captureError() {
  let resolve: (_?: unknown) => void;
  let reject: (e: Error) => void;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise,
    onError: (error: Error) => reject(error),
    clear: () => resolve()
  };
}

export function getSortValue(sortBy: SORT_BY_FIELD, sortOrder: SORT_ORDER): SortValues {
  return `${sortBy}:${sortOrder}`;
}

export function getInverseSortOrder(order: SORT_ORDER): SORT_ORDER {
  return order === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
}

type SortCallback = (itemA: IntegrationItemRecord, itemB: IntegrationItemRecord) => number;

const sortByDateAddedCallback: SortCallback = (itemA, itemB) => {
  if (!itemA.createdTime || !itemB.createdTime) return 0;
  if (itemA.createdTime === itemB.createdTime) return 0;

  const timestampA = new Date(itemA.createdTime).valueOf();
  const timestampB = new Date(itemB.createdTime).valueOf();

  return timestampA - timestampB;
};

const sortByNameCallback: SortCallback = (itemA, itemB) => {
  const nameA = itemA.name.toLowerCase();
  const nameB = itemB.name.toLowerCase();

  if (nameA === nameB) return 0;
  return nameA > nameB ? 1 : -1;
};

function getSortFn(sortBy: SORT_BY_FIELD): SortCallback {
  switch (sortBy) {
    case SORT_BY_FIELD.CREATED_TIME:
      return sortByDateAddedCallback;
    case SORT_BY_FIELD.NAME:
      return sortByNameCallback;
  }
}

export function filterItemsByName<T extends IntegrationItemRecord>(items: T[], search: string | undefined): T[] {
  if (!search) return items;

  const searchFilter = search.toLowerCase();
  return items.filter(item => item.name.toLowerCase().includes(searchFilter));
}

export function sortItems<T extends IntegrationItemRecord>(items: T[], sortBy: SORT_BY_FIELD, order: SORT_ORDER): T[] {
  const orderMultiplier = order === SORT_ORDER.ASC ? 1 : -1;
  const sortCallback = getSortFn(sortBy);

  return Array.from(items).sort((a, b) => sortCallback(a, b) * orderMultiplier);
}

export function filterFolders(items: IntegrationItemRecord[]): IntegrationFolder[] {
  return items.filter((item): item is IntegrationFolder => item.type === IntegrationFileType.FOLDER);
}

export function matchMimeType(mimeType: string, fileMimeType: string): boolean {
  if (mimeType === fileMimeType) return true;
  if (!mimeType.endsWith('/*')) return false;

  return mimeType.split('/', 1)[0] === fileMimeType.split('/', 1)[0];
}

export function filterItems(items: IntegrationItemRecord[], folderId: string | null | undefined, filter: string | undefined): IntegrationItemRecord[] {
  const mimeTypes = filter ? filter?.split('|') : Object.values(ALLOWED_MIME_TYPES);
  const folderIds = new Set(items.filter(item => item.type === IntegrationFileType.FOLDER).map(folder => folder.id));

  return items.filter(item => {
    if (item.type === IntegrationFileType.FOLDER) {
      if (filter) return false;

      return folderId ? item.directory === folderId : !item.directory || item.directory === ROOT_FOLDER_ID || !folderIds.has(item.directory);
    } else {
      const matchesDirectory = folderId === undefined ? true : (item.directory === folderId ?? ROOT_FOLDER_ID);

      if (matchesDirectory) {
        return !mimeTypes || mimeTypes.some(mimeType => matchMimeType(mimeType, item.mimeType || ''));
      }

      return false;
    }
  });
}

export function getRecordType(mimeType: string): RecordType {
  switch (true) {
    case matchMimeType(ALLOWED_MIME_TYPES.EPUB, mimeType):
      return RecordType.EPUB;
    case matchMimeType(ALLOWED_MIME_TYPES.ALL_TEXT, mimeType):
      return RecordType.TXT;
    default:
      return RecordType.PDF;
  }
}

export function isIntegrationFile<T extends NonNullable<unknown> | undefined>(file: IntegrationFile | T): file is IntegrationFile {
  return typeof file === 'object' && 'type' in file && file.type === IntegrationFileType.FILE;
}

export function isTextImport(data: IntegrationFile | File | string | TextImport | UrlImport): data is TextImport {
  return typeof data === 'object' && typeof (data as TextImport).title === 'string' && typeof (data as TextImport).text === 'string';
}

export function isUrlImport(data: IntegrationFile | File | string | TextImport | UrlImport): data is UrlImport {
  return typeof data === 'object' && typeof (data as UrlImport).url === 'string';
}

export type InstantListeningSupportedData = File | TextImport;

export function isInstantListeningV2Supported(data: IntegrationFile | File | string | TextImport | UrlImport): data is InstantListeningSupportedData {
  if (isTextImport(data)) {
    // we can simply create a dummy TXT file for inline text import
    return true;
  }
  if (data instanceof File) {
    return NEW_INSTANT_LISTENING_SUPPORTED_MIME_TYPES.includes(data.type);
  }
  return false;
}
