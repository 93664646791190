import React, { ReactNode } from 'react';

import Button from 'components/elements/Button';
import TextInput from 'components/elements/TextInput';
import { getDoc } from 'firebase/firestore';
import { IUser } from 'interfaces';
import { logError } from 'lib/observability';
import { deleteIntegration, setCanvasIntegration } from 'lib/speechify';
import { useForm } from 'react-hook-form';
import Tooltip from 'react-tooltip';
import { useDispatch } from 'store';
import { actions as toastActions } from 'store/toast';
import { useLocalStorage } from 'usehooks-ts';
import { logSegmentEvent } from 'utils/analytics';
import * as yup from 'yup';

import { Disclosure } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';

export type CanvasIntegrationData = {
  id: string;
  provider: string;
  type: string;
  apiBaseUrl: string;
  token: string;
  userId: string;
};

const DISCLOSE_BTN_CLASSNAME =
  'flex w-full justify-between px-4 py-2 text-left text-ABCDiatype text-xl font-bold hover:bg-glass-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-300 focus-visible:ring-opacity-75';

const withHttps = (url: string) => (!/:\/\//i.test(url) ? `https://${url}` : url);

const IntegrationDisclosure = ({ title, children, defaultOpen = true, ...props }: { title: string; defaultOpen?: boolean; children?: ReactNode }) => {
  return (
    <Disclosure as="div" className="border-b py-4" defaultOpen={defaultOpen} {...props}>
      {({ open }) => (
        <>
          <Disclosure.Button className={DISCLOSE_BTN_CLASSNAME}>
            <span>{title}</span>
            <ChevronUpIcon className={`${!open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`}></ChevronUpIcon>
          </Disclosure.Button>
          <Disclosure.Panel className="text-md px-4 pb-2 pt-4 font-normal text-glass-500">{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WebsiteSectionHighlight = (props: any) => {
  return <span className="rounded-md bg-glass-300 px-2 py-1 text-glass-500" {...props}></span>;
};

export type CanvasIntegrationFormFields = {
  baseUrl: string;
  accessToken: string;
};

const formSchema = yup.object().shape({
  baseUrl: yup.string().required('Base url is required'),
  accessToken: yup.string().required('Access token is required')
});

export default function CanvasIntegration({
  canvasData,
  user,
  setCanvasData
}: {
  canvasData: CanvasIntegrationData | null;
  user: IUser;
  setCanvasData: (data: CanvasIntegrationData) => void;
}) {
  // ESLint: '_' is assigned a value but never used
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCanvasBannerClosed] = useLocalStorage('canvas_lms_banner_closed', false);

  const {
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    register
  } = useForm<CanvasIntegrationFormFields>({
    resolver: yupResolver(formSchema),
    values: canvasData ? { accessToken: canvasData.token, baseUrl: canvasData.apiBaseUrl } : undefined
  });

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const onSubmitAddKey = async (data: CanvasIntegrationFormFields) => {
    // ESLint: 'accessToken' is never reassigned
    // eslint-disable-next-line prefer-const
    let { baseUrl } = data;
    const { accessToken } = data;
    try {
      baseUrl = new URL(withHttps(baseUrl.toLowerCase())).origin;
    } catch (e) {
      // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      logError(e);
      setLoading(false);
      setError('baseUrl', {
        message: 'Provided base url is not a valid URL. Please check whether you have specified correct URL for your university.'
      });
      return;
    }

    setLoading(true);
    const testCall = await fetch(`/api/checkCanvas`, {
      method: 'POST',
      body: JSON.stringify({ baseUrl, accessToken }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const testCallData = await testCall.json();

    if (testCallData.ok) {
      try {
        const integration = await setCanvasIntegration(baseUrl, accessToken, user.uid as string);
        const doc = await getDoc(integration);
        const data = doc.data() as CanvasIntegrationData;
        logSegmentEvent('web_app_canvas_integration_added', { url: baseUrl });
        setCanvasBannerClosed(true);

        dispatch(
          toastActions.add({
            title: 'Canvas integration successfully completed',
            description: 'Your canvas files should shortly show up in your library',
            type: 'success'
          })
        );

        setCanvasData({
          // @ts-expect-error TS(2783): 'id' is specified more than once, so this usage wi... Remove this comment to see the full error message
          id: integration.id,
          ...data
        });
      } catch (e) {
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        logError(e);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError('accessToken', {
        message: testCallData.message ?? 'Something went wrong. Please check data and try again'
      });
    }
  };

  const onSubmitRemoveKey = async () => {
    const confirmation = window.confirm("Do you want to remove your API token?\nThis will stop canvas integration but this won't delete any existing data");
    if (confirmation && canvasData?.id) {
      setLoading(true);
      try {
        await deleteIntegration(canvasData.id);
        logSegmentEvent('web_app_canvas_integration_removed', { url: canvasData.apiBaseUrl });
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        setCanvasData(null);
        reset({ accessToken: '', baseUrl: '' });
        dispatch(
          toastActions.add({
            title: 'Canvas integration successfully removed',
            description: 'Your existing files will remain in your library',
            type: 'success'
          })
        );
      } catch (e) {
        // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        logError(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex max-w-2xl flex-col gap-2">
      <IntegrationDisclosure defaultOpen={canvasData === null} title="How Canvas integrates with Speechify?">
        Speechify and Canvas integration allows you to sync all your assigned reading materials into Speechify library and listen anytime you want it. With this
        integration Speechify will mirror all your courses materials in library within the created folder.
      </IntegrationDisclosure>

      <IntegrationDisclosure defaultOpen={canvasData === null} title="How to sync Canvas reading material?">
        In order to sync your Canvas files you need to generate your API Token specifically for Speechify. Please follow the instructions in the following
        tutorial:
        <ol className="my-2 flex list-inside list-decimal flex-col gap-6 rounded border p-4">
          <li>
            Log into your Canvas account, on the left, click <WebsiteSectionHighlight>Account</WebsiteSectionHighlight> then open{' '}
            <WebsiteSectionHighlight>Settings</WebsiteSectionHighlight>. <img src="/images/dashboard/canvas_lms_1.png" className="my-2" />
          </li>
          <li>
            {/* ESLint: `"` can be escaped with `&quot;`, `&ldquo;`, `&#34;`, `&rdquo;` & `"` can be escaped with `&quot;`, `&ldquo;`, `&#34;`, `&rdquo;` */}
            {/* eslint-disable-next-line react/no-unescaped-entities, react/no-unescaped-entities */}
            Scroll down the settings page until you find the "Approved Integrations" section. Locate and click{' '}
            <WebsiteSectionHighlight>+ New Access Token.</WebsiteSectionHighlight>
            <img className="my-2" src="/images/dashboard/canvas_lms_2.png" />
          </li>
          {/* ESLint: `"` can be escaped with `&quot;`, `&ldquo;`, `&#34;`, `&rdquo;` & `"` can be escaped with `&quot;`, `&ldquo;`, `&#34;`, `&rdquo;` */}
          {/* eslint-disable-next-line react/no-unescaped-entities, react/no-unescaped-entities */}
          <li className="leading-8">
            {/* ESLint: `'` can be escaped with `&apos;`, `&lsquo;`, `&#39;`, `&rsquo;` */}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Fill in <WebsiteSectionHighlight>Purpose</WebsiteSectionHighlight> field of the new Token, e.g. "Speechify".
            <br />
            {/* ESLint: `'` can be escaped with `&apos;`, `&lsquo;`, `&#39;`, `&rsquo;` */}
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Leave <WebsiteSectionHighlight>Expires</WebsiteSectionHighlight> field empty if you don't want the token to expire
          </li>
          <li>Copy token and paste it in the fields below.</li>
        </ol>
      </IntegrationDisclosure>
      <div className="flex w-full flex-col gap-2 px-4 py-2">
        <h3 className="font-ABCDiatype text-xl font-bold">Canvas Credentials</h3>
        <Tooltip className="canvas-tooltip" id="base-url-tooltip" type="dark" effect="solid" backgroundColor="#14202E" textColor="#D9D9D9">
          <div className="w-auto  p-1" style={{ maxWidth: '280px' }}>
            <p className="text-base">Located in your browser’s address bar when you login to Canvas:</p>
            <img src="/images/dashboard/canvas_lms_3.png" className="h-auto w-full" />
          </div>
        </Tooltip>
        <Tooltip className="canvas-tooltip" id="access-token-tooltip" type="dark" effect="solid" backgroundColor="#14202E" textColor="#D9D9D9">
          <div className="w-auto  p-1" style={{ maxWidth: '280px' }}>
            <p className="text-base">Located in first row of Access Token Details window:</p>
            <img src="/images/dashboard/canvas_lms_4.png" className="h-auto w-full" />
          </div>
        </Tooltip>
        <form className="flex max-w-sm flex-col gap-4" onSubmit={handleSubmit(canvasData?.id ? onSubmitRemoveKey : onSubmitAddKey)}>
          <div>
            <label htmlFor="baseUrl" className="inline-flex items-center gap-2">
              Institution Base Url
              <QuestionMarkCircleIcon data-tip data-for="base-url-tooltip" className="h-5 w-5 cursor-pointer text-gray-500" />
            </label>
            <TextInput
              disabled={canvasData?.id}
              error={errors.baseUrl}
              id="baseUrl"
              // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
              name="baseUrl"
              type="text"
              placeholder="https://canvas.vt.edu"
              {...register('baseUrl')}
            ></TextInput>
          </div>
          <div>
            <label htmlFor="accessToken" className="inline-flex items-center gap-2">
              Canvas Access Token
              <QuestionMarkCircleIcon data-tip data-for="access-token-tooltip" className="h-5 w-5 cursor-pointer text-gray-500" />
            </label>
            <TextInput
              error={errors.accessToken}
              id="accessToken"
              disabled={canvasData?.id}
              // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ... Remove this comment to see the full error message
              name="accessToken"
              type="text"
              placeholder="1234~xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
              {...register('accessToken')}
            ></TextInput>
          </div>
          <div>
            <Button className="max-w-xs" full type="submit" color={canvasData?.id ? 'red' : 'primary'}>
              {loading ? 'Loading....' : canvasData?.id ? 'Remove API Token' : 'Integrate Canvas'}
            </Button>
            {canvasData?.id ? (
              // ESLint: `'` can be escaped with `&apos;`, `&lsquo;`, `&#39;`, `&rsquo;`.eslintreact/no-unescaped-entities
              // eslint-disable-next-line react/no-unescaped-entities
              <span className={'text-xs text-glass-500'}>Your synced files won't be deleted if you remove your API key.</span>
            ) : (
              ''
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
