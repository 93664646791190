import { useTranslation } from 'hooks/useTypedTranslation';
import React, { useCallback } from 'react';

interface ImportButtonProps {
  isImporting: boolean;
  isImported: boolean;
  onClick: () => void;
}

export function ImportButton({ isImported, isImporting, onClick }: ImportButtonProps) {
  const { t } = useTranslation('common');
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  if (isImported) {
    return (
      <span className="text-sm align-text-top text-electric-400 cursor-pointer" onClick={handleClick}>
        {t('Listen')}
      </span>
    );
  }

  if (isImporting) {
    return (
      <div className="inline-block align-middle bg-glass-300 rounded-xl h-1 w-10 mt-[-2px] overflow-hidden">
        <div className="bg-electric-400 animate-indeterminiteProgress rounded-xl h-1" />
      </div>
    );
  }

  return (
    <button className="bg-electric-400 align-text-top rounded-full text-xs text-glass-0 py-0.5 px-2" onClick={handleClick}>
      {t('Import')}
    </button>
  );
}
