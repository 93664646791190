import React, { Fragment, useEffect, useState } from 'react';

import ArrowDown from 'assets/icons/arrow-down';
import ArrowRight from 'assets/icons/arrow-right';
import Folder from 'assets/icons/folder';
import TrashIcon from 'assets/icons/trash';
import ItemActionMenu from 'components/library/items/ActionMenu';
import { IRecord, ItemActionType } from 'interfaces';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'store';
import { selectors as librarySelectors } from 'store/library';
import { twMerge } from 'tailwind-merge';
import { doItemsMatch } from 'utils';

import { useDroppable } from '@dnd-kit/core';
import { DocumentTextIcon } from '@heroicons/react/outline';

interface TreeNodeProps {
  id: string;
  isActive: boolean;
  isChildActive?: boolean;
  hasChildren?: boolean;
  isActiveDropTarget?: boolean;
  isMain?: boolean;
  onAction?: (folder: IRecord, actionType: ItemActionType) => void;
  onClick: (folderId: string) => void;
  title: string;
  level?: number;
}

export const TreeNode: React.FC<React.PropsWithChildren<TreeNodeProps>> = ({
  children,
  id,
  isActive,
  isChildActive,
  isMain,
  isActiveDropTarget,
  hasChildren,
  onAction,
  onClick,
  title,
  level = 0
}) => {
  const item = useSelector(state => librarySelectors.getFolderById(id)(state), doItemsMatch);

  // state
  const [isOpen, setIsOpen] = useState(false);

  // dnd
  const { setNodeRef: setDropRef } = useDroppable({ id: `library-item-${id}-drop` });

  useEffect(() => {
    setIsOpen(isOpen => isOpen || isChildActive || isActive);
  }, [isChildActive, isActive]);

  useEffect(() => {
    if (isActiveDropTarget && !isOpen) {
      setIsOpen(true);
    }
    // ESLint: React Hook useEffect has a missing dependency: 'isOpen'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveDropTarget]);

  const handleActionClick = (actionType: ItemActionType) => {
    // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onAction(item, actionType);
  };

  const handleClick = () => {
    onClick(id);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const rootPadding = 16;
  const paddingLeft = isMain ? 0 : rootPadding + level * 12;

  return (
    <Fragment>
      <div
        className={twMerge(
          'group flex h-9 items-center rounded-[10px] py-[2px] pr-0 cursor-pointer',
          isActiveDropTarget ? 'border-glass-400 border bg-[#E8EFFF] ' : isActive ? 'bg-glass-300' : 'hover:bg-glass-200'
        )}
        style={{ paddingLeft: `${paddingLeft}px` }}
        onClick={handleClick}
        ref={setDropRef}
        data-tip
        data-for={`tooltip-treenode-folder-${id}`}
      >
        {hasChildren && isOpen ? (
          <ArrowDown style={{ minWidth: '20px' }} className="ml-0 h-5 w-5 cursor-pointer text-gray-400" aria-hidden="true" onClick={handleToggle} />
        ) : hasChildren && !isOpen ? (
          <ArrowRight style={{ minWidth: '20px' }} className="ml-0 h-5 w-5 cursor-pointer text-gray-400" aria-hidden="true" onClick={handleToggle} />
        ) : (
          <div style={{ minWidth: '20px' }} />
        )}
        <div className="flex items-center w-full">
          {id === 'root' ? (
            <DocumentTextIcon style={{ minWidth: '20px' }} className="h-5 w-5 text-glass-500" aria-hidden="true" />
          ) : id === 'trash' ? (
            <TrashIcon style={{ minWidth: '20px' }} aria-hidden="true" />
          ) : (
            <Folder style={{ minWidth: '20px' }} />
          )}
          <div className="group relative flex w-full items-center rounded-md px-1 pl-2 text-sm font-medium text-glass-700">
            <span title={title} className={'truncate pr-5'} style={{ maxWidth: `${182 - paddingLeft}px` }}>
              {title}
            </span>
            <div className="absolute right-0 ml-auto inline-block p-0 pt-[6px]" aria-hidden="true">
              {onAction && <ItemActionMenu isFolder={true} onAction={handleActionClick} hideOnMouseOut />}
              {!onAction && <div className="h-8" />}
            </div>
          </div>
        </div>
        <ReactTooltip
          id={`tooltip-treenode-folder-${id}`}
          place="right"
          type="dark"
          effect="solid"
          backgroundColor="#14202E"
          textColor="#D9D9D9"
          className="text-xs"
        >
          <span className="relative z-10">{title}</span>
        </ReactTooltip>
      </div>
      {hasChildren && isOpen && <div>{children}</div>}
    </Fragment>
  );
};

export default TreeNode;
