import React, { Fragment, useState } from 'react';

import { useOnClickOutside } from 'hooks/useClickOutside';
import { IRecord, IUser } from 'interfaces';
import { useSelector } from 'store';
import { twMerge } from 'tailwind-merge';
import { useIntercomDefault } from 'utils/intercom';

import { Menu, Transition } from '@headlessui/react';

type IntercomHelpButtonProps = {
  handleClose: () => void;
  handleTabClose: () => void;
};

const IntercomHelpButton = React.forwardRef<HTMLButtonElement, IntercomHelpButtonProps>((props, ref) => {
  const { handleClose, handleTabClose } = props;
  // @ts-expect-error TS(2322): Type 'IUser | null' is not assignable to type 'IUs... Remove this comment to see the full error message
  const currentUser = useSelector<IUser>(state => state.auth.user);

  useIntercomDefault(currentUser);

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          ref={ref}
          className={twMerge(
            'support-menu-help-center',
            active ? 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0' : 'text-glass-700 dark:text-glass-0',
            'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
          )}
          onClick={() => {
            handleTabClose();
            handleClose();
            // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
            window['Intercom'] && window['Intercom']('show');
          }}
        >
          Help Center
        </button>
      )}
    </Menu.Item>
  );
});
IntercomHelpButton.displayName = 'IntercomHelpButton';

interface SupportMenuProps {
  item: IRecord;
  isOpen: boolean;
  handleClose: () => void;
  handleTabClose: () => void;
  handleReportToggle: () => void;
  buttonRef: React.MutableRefObject<HTMLButtonElement>;
}

export const SupportMenu: React.FC<SupportMenuProps> = props => {
  const { isOpen, handleClose, handleTabClose, handleReportToggle, buttonRef } = props;

  const [containerDiv, setContainerDiv] = useState<HTMLDivElement | null>(null);

  useOnClickOutside([containerDiv, buttonRef], handleClose);

  return (
    <Fragment>
      <Menu
        as="div"
        className={twMerge(
          'pointer-events-auto',
          'fixed',
          'md:left-20',
          'md:right-auto',
          'right-4',
          'md:bottom-[60px]',
          'top-14',
          'md:top-auto',
          'z-2000',
          'inline-flex',
          'text-left',
          'bg-glass-0',
          'dark:bg-glass-700'
        )}
      >
        <Fragment>
          <Transition
            show={isOpen}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            <Menu.Items static>
              <div
                ref={ref => setContainerDiv(ref)}
                className={twMerge(
                  'origin-top-right',
                  'divide-y',
                  'divide-glass-300',
                  'dark:divide-glass-600',
                  'overflow-hidden',
                  'rounded-[5px]',
                  'bg-glass-0',
                  'dark:bg-glass-700',
                  'font-ABCDiatype',
                  'shadow-lg',
                  'ring-1',
                  'ring-glass-300',
                  'dark:ring-glass-600',
                  'focus:outline-none'
                )}
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={twMerge(
                          active ? 'bg-glass-200 text-glass-900 dark:bg-glass-800 dark:text-glass-0' : 'text-glass-700 dark:text-glass-0',
                          'group flex w-full items-center gap-3 px-3 py-2 text-sm opacity-90'
                        )}
                        onClick={() => {
                          handleReportToggle();
                          handleClose();
                        }}
                      >
                        Report broken experience
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <IntercomHelpButton handleClose={handleClose} handleTabClose={handleTabClose} />
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </Fragment>
      </Menu>
    </Fragment>
  );
};

export default SupportMenu;
